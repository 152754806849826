import * as React from 'react';
import { Alert, Link } from '@passthrough/uikit';
import * as riskConstants from './constants';

const SEVERITY = {
  [riskConstants.CRITICAL_RISK]: 'error',
  [riskConstants.HIGH_RISK]: 'error',
  [riskConstants.MODERATE_RISK]: 'warning',
  [riskConstants.LOW_RISK]: 'success',
  [riskConstants.NO_RATING]: 'warning',
};

const ALERT_MESSAGE = {
  [riskConstants.CRITICAL_RISK]:
    'The recommended risk rating for this investor is critical.',
  [riskConstants.HIGH_RISK]:
    'The recommended risk rating for this investor is high.',
  [riskConstants.MODERATE_RISK]:
    'The recommended risk rating for this investor is moderate.',
  [riskConstants.LOW_RISK]:
    'The recommended risk rating for this investor is low.',
  [riskConstants.NO_RATING]:
    'The risk of the investor is unable to be assessed.',
};

export function RiskRecommendationAlert({ diligence, goToSubmission }) {
  const severity = SEVERITY[diligence?.riskRecommendation];
  const message = ALERT_MESSAGE[diligence?.riskRecommendation];

  if (!severity || !message) {
    return null;
  }

  return (
    <Alert
      severity={severity}
      action={
        goToSubmission ? (
          <Link tab="diligence" href={diligence.reviewUrl}>
            Go to submission
          </Link>
        ) : null
      }
    >
      {message}
    </Alert>
  );
}

import React from 'react';
import { Chip, Icons } from '@passthrough/uikit';

import * as riskConstants from 'components/risk_rating/constants';

function getDisplayLabel(riskRating) {
  switch (riskRating) {
    case riskConstants.NO_RATING:
      return 'Unable to assess';
    case riskConstants.CRITICAL_RISK:
      return 'Critical';
    case riskConstants.HIGH_RISK:
      return 'High';
    case riskConstants.MODERATE_RISK:
      return 'Moderate';
    case riskConstants.LOW_RISK:
      return 'Low';

    default:
      return null;
  }
}

function getVariant(riskRating) {
  switch (riskRating) {
    case riskConstants.NO_RATING:
      return 'neutral';
    case riskConstants.CRITICAL_RISK:
      return 'error';
    case riskConstants.HIGH_RISK:
      return 'error';
    case riskConstants.MODERATE_RISK:
      return 'warning';
    case riskConstants.LOW_RISK:
      return 'success';
    default:
      return null;
  }
}

function getIcon(riskRating) {
  switch (riskRating) {
    case riskConstants.CRITICAL_RISK:
      return <Icons.ErrorOutline />;
    default:
      return null;
  }
}

export function RiskRatingChip({ riskRating }) {
  if (!riskRating) {
    return null;
  }

  return (
    <Chip
      variant={getVariant(riskRating)}
      label={getDisplayLabel(riskRating)}
      icon={getIcon(riskRating)}
      size="small"
    />
  );
}

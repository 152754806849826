import * as React from 'react';

import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, Popper } from '@material-ui/core';

import RepeatIcon from '@material-ui/icons/Repeat';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  popper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
  },

  repeatIcon: {
    color: theme.palette.primary.main,
  },

  optionListItem: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(1),
    alignItems: 'center',
    flexGrow: 1,
  },

  optionNameAndType: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
  },

  optionType: {
    color: theme.palette.text.secondary,
  },

  inputField: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const filterOptions = createFilterOptions();

export function NameAutocomplete({
  nodeChoices,
  onChange,
  id,
  type,
  containerRef,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  const customPopper = (props) => {
    const containerWidth = containerRef.current?.clientWidth || 0;
    const popperWidth = containerWidth - 44;

    const width = popperWidth > 0 ? popperWidth : 'full-width';
    return (
      <Popper
        className={classes.popper}
        {...props}
        style={{
          width,
        }}
        placement="bottom-start"
      />
    );
  };

  const saveNode = (newValue) => {
    onChange({ id, name: newValue || '', type: '' });
  };

  const handleChange = (event, newValue) => {
    if (newValue === null) {
      onChange({ id, name: '', type: '' });
    } else if (typeof newValue === 'string') {
      saveNode(newValue);
    } else {
      const newName = newValue?.newOption
        ? {
            id,
            name: newValue?.name || '',
            type,
          }
        : {
            id,
            name: newValue?.name,
            type: newValue?.type,
            idToReuse: newValue?.id,
          };

      setInputValue(newName?.name || '');
      setValue(newName);
      onChange(newName);
    }
  };

  const getIcon = (option) => {
    if (option?.newOption) {
      return <AddIcon />;
    }
    return <RepeatIcon />;
  };

  const renderOption = (option, props) => {
    const { key, ...optionProps } = props;
    return (
      <div key={key} {...optionProps} className={classes.optionListItem}>
        <div className={classes.repeatIcon}>{getIcon(option)}</div>
        <div className={classes.optionNameAndType}>
          <span>
            {option?.newOption ? `Add "${option?.name}"` : option?.name}
          </span>
          <span className={classes.optionType}>{option?.type}</span>
        </div>
      </div>
    );
  };

  const existingNode = nodeChoices.find((node) => inputValue === node.name);
  return (
    <Autocomplete
      selectOnFocus
      freeSolo
      fullWidth
      handleHomeEndKeys
      autoHighlight
      value={value}
      options={nodeChoices}
      PopperComponent={customPopper}
      containerRef={containerRef}
      onChange={handleChange}
      filterOptions={(options, params) => {
        const filtered = filterOptions(options, params);

        if (params.inputValue !== '') {
          filtered.push({
            newOption: true,
            name: params.inputValue,
          });
        }
        return filtered;
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        saveNode(newInputValue);
      }}
      renderOption={renderOption}
      getOptionLabel={(option) => option?.name || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Full legal name"
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {existingNode &&
                  value?.idToReuse &&
                  (type === '' || type === existingNode.type) && (
                    <InputAdornment position="start">
                      <RepeatIcon className={classes.repeatIcon} />
                    </InputAdornment>
                  )}
              </>
            ),
            style: {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
            autoComplete: 'chrome-off',
          }}
        />
      )}
    />
  );
}

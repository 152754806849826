import React, { useState } from 'react';
import {
  Modal,
  Alert,
  Typography,
  Link,
  UIKitSettingsProvider,
  Icons,
} from '@passthrough/uikit';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MessageIcon from '@material-ui/icons/MessageOutlined';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined';
import FingerprintOffIcon from 'mdi-material-ui/FingerprintOff';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { useToast } from 'services/toast';
import * as api from 'services/api';
import { KeyValuePair, KeyValuePairs } from 'components/key_value';
import { CountersignerNamesDisplay } from 'pages/fund_closing_overview/countersigner_names_display';
import { Spinner } from 'components/spinner';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from 'components/table/index';
import { GPSelectionInput } from 'components/gp_selection_input/index';
import * as constants from 'components/gp_selection_input/constants';
import { useDiscardConfirmation } from 'services/discard';
import Tooltip from '@material-ui/core/Tooltip';
import { useFeatureFlags } from 'services/providers/feature_flag';

const useStyles = makeStyles((theme) => ({
  docLink: {
    margin: theme.spacing(0.5, 0),
  },
  extraCountersigner: {
    marginTop: theme.spacing(2),
  },
  messageDisplay: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.neutral.fill,
  },
  stepRoot: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: theme.spacing(2),
  },
  withCounter: {
    // display a counter on the right side
    // the counter should always display and never overflow
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& > *:last-child': {
      flexShrink: 0,
    },
  },
  tooltipIcon: {
    fontSize: '1.125rem',
  },
  withIcon: {
    display: 'flex',
    alignItems: 'flex-end',
    columnGap: '2px',
    '& > *:first-child': {
      flexShrink: 0,
    },
  },
}));

const EDIT_STEP = 0;
const REVIEW_STEP = 1;

function EditStepContent({
  nonFieldError,
  numberOfCountersigners,
  members,
  fundId,
  message,
  setMessage,
  messageError,
  subscriptionDoc,
  countersignerId,
  setCountersignerId,
  countersignerIdError,
  countersigner2Id,
  setCountersigner2Id,
  countersigner2IdError,
  countersigner3Id,
  setCountersigner3Id,
  countersigner3IdError,
  diligenceEnabled,
  diligenceApproverId,
  setDiligenceApproverId,
  diligenceApproverIdError,
}) {
  const classes = useStyles();

  return (
    <div className={classes.stepRoot}>
      {nonFieldError ? <Alert severity="error">{nonFieldError}</Alert> : null}

      {numberOfCountersigners > 0 ? (
        <KeyValuePair input icon={<BorderColorIcon />}>
          <GPSelectionInput
            label={subscriptionDoc.countersigner1DisplayName}
            errorMsg={countersignerIdError}
            value={countersignerId}
            setValue={setCountersignerId}
            roleLabelNum={constants.DEFAULT_COUNTERSIGNER_1_LABEL_NUM}
            members={members}
            fundId={fundId}
          />
        </KeyValuePair>
      ) : null}

      {numberOfCountersigners > 1 ? (
        <KeyValuePair input icon={<BorderColorIcon />}>
          <GPSelectionInput
            className={classes.extraCountersigner}
            label={subscriptionDoc.countersigner2DisplayName}
            errorMsg={countersigner2IdError}
            value={countersigner2Id}
            setValue={setCountersigner2Id}
            roleLabelNum={constants.DEFAULT_COUNTERSIGNER_2_LABEL_NUM}
            members={members}
            fundId={fundId}
          />
        </KeyValuePair>
      ) : null}

      {numberOfCountersigners > 2 ? (
        <KeyValuePair input icon={<BorderColorIcon />}>
          <GPSelectionInput
            className={classes.extraCountersigner}
            label={subscriptionDoc.countersigner3DisplayName}
            errorMsg={countersigner3IdError}
            value={countersigner3Id}
            setValue={setCountersigner3Id}
            roleLabelNum={constants.DEFAULT_COUNTERSIGNER_3_LABEL_NUM}
            members={members}
            fundId={fundId}
          />
        </KeyValuePair>
      ) : null}

      {diligenceEnabled ? (
        <KeyValuePair input icon={<BorderColorIcon />}>
          <GPSelectionInput
            label="Diligence reviewer"
            errorMsg={diligenceApproverIdError}
            value={diligenceApproverId}
            setValue={setDiligenceApproverId}
            roleLabelNum={constants.DEFAULT_DILIGENCE_APPROVER_LABEL_NUM}
            members={members}
            fundId={fundId}
            fundPermissionLevel={constants.ACCESS_LEVEL_REVIEW}
          />
        </KeyValuePair>
      ) : null}

      <KeyValuePair input icon={<MessageIcon />}>
        <TextField
          id="message-to-investor"
          label="Include a message (optional)"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          variant="outlined"
          multiline
          fullWidth
          error={!!messageError}
          helperText={
            <Typography variant="label" size="small">
              {messageError ||
                'This message will appear in the email to investors.'}
            </Typography>
          }
          minRows={4}
        />
      </KeyValuePair>
    </div>
  );
}

function hasDiligenceLpClosing(investor) {
  return Boolean(
    investor.diligenceLpClosingId || investor.diligenceLpClosing?.id,
  );
}

function ReviewStepContent({
  members,
  message,
  investors,
  documents,
  diligenceEnabled,
  numberOfCountersigners,
  countersignerId,
  countersigner2Id,
  countersigner3Id,
  diligenceApproverId,
  diligenceClosingName,
}) {
  const classes = useStyles();
  const diligenceCount =
    investors?.filter((investor) => hasDiligenceLpClosing(investor)).length ||
    0;
  const diligencePlural = diligenceCount > 1 ? 's' : '';
  const subdocsCount = investors?.length || 0;
  const subdocsPlural = subdocsCount > 1 ? 's' : '';

  const { SPLIT_QUESTIONNAIRE_DILIGENCE_CLOSINGS } = useFeatureFlags();

  return (
    <div className={classes.stepRoot}>
      <Typography>
        The {investors.length > 1 ? 'investors ' : 'investor '}
        will be notified by email.
      </Typography>

      <KeyValuePairs>
        <CountersignerNamesDisplay
          numberOfCountersigners={numberOfCountersigners}
          members={members}
          countersignerId={countersignerId}
          countersigner2Id={countersigner2Id}
          countersigner3Id={countersigner3Id}
          Icon={<BorderColorIcon />}
        />

        {diligenceEnabled ? (
          <>
            <KeyValuePair
              icon={<BorderColorIcon />}
              label={
                <Typography size="small" color="text.secondary">
                  Diligence reviewer
                </Typography>
              }
            >
              {members.find((m) => m.id === diligenceApproverId).name}
            </KeyValuePair>

            <Divider />
          </>
        ) : null}

        <KeyValuePair
          icon={<MessageIcon />}
          label={
            <Typography size="small" color={message ? 'text.secondary' : null}>
              {message ? 'Message to include' : 'No message'}
            </Typography>
          }
        >
          {message ? (
            <Paper className={classes.messageDisplay} elevation={0}>
              <Typography multiline size="small">
                {message}
              </Typography>
            </Paper>
          ) : null}
        </KeyValuePair>

        {documents.length > 0 ? (
          <>
            <Divider />

            {SPLIT_QUESTIONNAIRE_DILIGENCE_CLOSINGS ? (
              <div className={classes.withCounter}>
                <KeyValuePair icon={<DescriptionIcon />} label="">
                  {documents.map((doc) => (
                    <div key={doc.id} className={classes.docLink}>
                      <Link variant="external" href={doc.url}>
                        <Typography size="small" component="span">
                          {doc.name}
                        </Typography>
                      </Link>
                    </div>
                  ))}
                </KeyValuePair>
                <Typography variant="label" color="text.secondary" noWrap>
                  {subdocsCount} investor{subdocsPlural}
                </Typography>
              </div>
            ) : (
              <KeyValuePair icon={<DescriptionIcon />} label="">
                {documents.map((doc) => (
                  <div key={doc.id} className={classes.docLink}>
                    <Link variant="external" href={doc.url}>
                      <Typography size="small" component="span">
                        {doc.name}
                      </Typography>
                    </Link>
                  </div>
                ))}
              </KeyValuePair>
            )}
          </>
        ) : null}
        {SPLIT_QUESTIONNAIRE_DILIGENCE_CLOSINGS &&
        diligenceCount &&
        diligenceClosingName ? (
          <>
            <Divider />
            <div className={classes.withCounter}>
              <KeyValuePair
                icon={<Icons.FingerprintOutlined />}
                label={
                  <div className={classes.withIcon}>
                    <Typography size="small" color="text.secondary">
                      Diligence jurisdiction
                    </Typography>
                    <Typography
                      variant="label"
                      size="small"
                      color="text.secondary"
                    >
                      <Tooltip
                        title={
                          <Typography variant="label" size="medium">
                            Investors will receive diligence questions to
                            identify their beneficial ownership structure and
                            are screened to mitigate any potential risks.
                          </Typography>
                        }
                      >
                        <Icons.InfoOutlined fontSize="inherit" />
                      </Tooltip>
                    </Typography>
                  </div>
                }
              >
                {diligenceClosingName}
              </KeyValuePair>
              <Typography variant="label" color="text.secondary" noWrap>
                {diligenceCount} investor{diligencePlural}
              </Typography>
            </div>
          </>
        ) : null}
      </KeyValuePairs>

      <Table noTopMargin>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography size="small">Investor</Typography>
            </TableCell>
            <TableCell>
              <Typography size="small">Documents</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {investors.map((investor) => (
            <TableRow key={investor.id}>
              <TableCell scope="row">
                <div className={classes.withIcon}>
                  <Typography>{investor.lpName}</Typography>{' '}
                  {SPLIT_QUESTIONNAIRE_DILIGENCE_CLOSINGS &&
                  diligenceCount &&
                  diligenceClosingName &&
                  !hasDiligenceLpClosing(investor) ? (
                    <Typography variant="label" color="text.secondary">
                      <Tooltip
                        title={
                          <Typography variant="label" size="medium">
                            Excluded from diligence
                          </Typography>
                        }
                      >
                        <FingerprintOffIcon fontSize="inherit" />
                      </Tooltip>
                    </Typography>
                  ) : null}
                </div>
              </TableCell>

              <TableCell>
                {investor.offerings.map((doc) => (
                  <div key={doc.id} className={classes.docLink}>
                    <Link variant="external" href={doc.url}>
                      <Typography size="small" component="span">
                        {doc.name}
                      </Typography>
                    </Link>
                  </div>
                ))}
                {investor.sideletters.map((doc) => (
                  <div key={doc.id} className={classes.docLink}>
                    <Link variant="external" href={doc.url}>
                      <Typography size="small" component="span">
                        {doc.name}
                      </Typography>
                    </Link>
                  </div>
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export function SendDialog({
  open,
  onChange,
  handleClose,
  investors,
  diligenceEnabled,
  closing = {},
  subscriptionDoc,
  members,
}) {
  const { fundId, closingId } = useParams();
  const { successToast } = useToast();
  const [message, setMessage] = useState(closing.defaultInvitationText);
  const [messageError, setMessageError] = useState('');
  const [nonFieldError, setNonFieldError] = useState('');
  const [countersignerId, setCountersignerId] = useState(
    closing.defaultCountersignerId,
  );
  const [countersignerIdError, setCountersignerIdError] = useState('');
  const [countersigner2Id, setCountersigner2Id] = useState(
    closing.defaultCountersigner2Id,
  );
  const [countersigner2IdError, setCountersigner2IdError] = useState('');
  const [countersigner3Id, setCountersigner3Id] = useState(
    closing.defaultCountersigner3Id,
  );
  const [countersigner3IdError, setCountersigner3IdError] = useState('');
  const [diligenceApproverId, setDiligenceApproverId] = useState(
    closing.defaultDiligenceApproverId,
  );
  const [diligenceApproverIdError, setDiligenceApproverIdError] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [step, setStep] = useState(EDIT_STEP);
  const [hasUnsavedData, setHasUnsavedData] = useState(false);

  const closeWithConfirmation = useDiscardConfirmation({
    onConfirm: handleClose,
    needConfirmation: hasUnsavedData,
  });

  const count = investors.length;
  const plural = count > 1 ? 's' : '';
  const numberOfCountersigners = subscriptionDoc?.numberOfCountersigners || 0;

  const documents = [];
  if (subscriptionDoc?.url) {
    documents.push({
      id: 'subdoc',
      url: subscriptionDoc.url,
      name: subscriptionDoc.name,
    });
  }

  const shouldShowSpinner = members === null || subscriptionDoc === null;

  function onNext(e) {
    e.preventDefault();
    setStep(REVIEW_STEP);
  }

  function onSubmit(e) {
    e.preventDefault();
    setIsSending(true);
    const lpClosingIds = investors.map((i) => i.id);

    api
      .sendClosingDocs({
        fundId,
        closingId,
        lpClosingIds,
        message,
        countersignerId,
        countersigner2Id,
        countersigner3Id,
        diligenceApproverId,
      })
      .then(() => {
        successToast(`Sent closing docs to ${count} investor${plural}`);
        onChange();
        handleClose();
      })
      .catch((error) => {
        if (error.response?.status === 400) {
          setNonFieldError(error.response.data.detail);
          setMessageError(error.response.data.message);
          setCountersignerIdError(error.response.data.countersignerId);
          setCountersigner2IdError(error.response.data.countersigner2Id);
          setCountersigner3IdError(error.response.data.countersigner3Id);
          setDiligenceApproverIdError(error.response.data.diligenceApproverId);
          setStep(EDIT_STEP);
          setIsSending(false);
        }
      });
  }

  function getIsDisabledByCountersigner() {
    if (numberOfCountersigners === 0) {
      return false;
    }
    if (numberOfCountersigners === 1) {
      return !countersignerId;
    }
    if (numberOfCountersigners === 2) {
      return !countersignerId || !countersigner2Id;
    }
    if (numberOfCountersigners === 3) {
      return !countersignerId || !countersigner2Id || !countersigner3Id;
    }
    return true;
  }

  function getIsDisabledByDiligence() {
    if (diligenceEnabled) {
      return !diligenceApproverId;
    }
    return false;
  }

  function getIsDisabled() {
    return getIsDisabledByCountersigner() || getIsDisabledByDiligence();
  }

  return (
    <Modal
      open={open}
      onClose={closeWithConfirmation}
      onEntering={() => {
        setStep(EDIT_STEP);
        setIsSending(false);
        setHasUnsavedData(false);
        setDiligenceApproverId(closing.defaultDiligenceApproverId || '');
        setCountersignerId(closing.defaultCountersignerId || '');
        setCountersigner2Id(closing.defaultCountersigner2Id || '');
        setCountersigner3Id(closing.defaultCountersigner3Id || '');
        setMessage(closing.defaultInvitationText || '');
      }}
      showCancelButton
      headerLabel={`Send to investor${plural}`}
      subheaderLabel={`${count} investor${plural} selected`}
      primaryButtonText={step === EDIT_STEP ? 'Next' : 'Send'}
      primaryButtonEndIcon={step === EDIT_STEP ? <ArrowForwardIcon /> : null}
      primaryButtonDisabled={step === EDIT_STEP ? getIsDisabled() : null}
      primaryButtonLoading={isSending}
      onSubmit={step === EDIT_STEP ? onNext : onSubmit}
      onBack={step === EDIT_STEP ? null : () => setStep(EDIT_STEP)}
      primaryButtonProps={{
        'data-test': step === EDIT_STEP ? 'dialog-next' : 'dialog-send',
      }}
    >
      {shouldShowSpinner ? <Spinner /> : null}

      {!shouldShowSpinner && step === EDIT_STEP ? (
        <EditStepContent
          nonFieldError={nonFieldError}
          numberOfCountersigners={numberOfCountersigners}
          members={members}
          fundId={fundId}
          message={message}
          setMessage={(val) => {
            setHasUnsavedData(true);
            setMessage(val);
          }}
          messageError={messageError}
          subscriptionDoc={subscriptionDoc}
          countersignerId={countersignerId}
          setCountersignerId={(val) => {
            setHasUnsavedData(true);
            setCountersignerId(val);
          }}
          countersignerIdError={countersignerIdError}
          countersigner2Id={countersigner2Id}
          setCountersigner2Id={(val) => {
            setHasUnsavedData(true);
            setCountersigner2Id(val);
          }}
          countersigner2IdError={countersigner2IdError}
          countersigner3Id={countersigner3Id}
          setCountersigner3Id={(val) => {
            setHasUnsavedData(true);
            setCountersigner3Id(val);
          }}
          countersigner3IdError={countersigner3IdError}
          diligenceEnabled={diligenceEnabled}
          diligenceApproverId={diligenceApproverId}
          setDiligenceApproverId={(val) => {
            setHasUnsavedData(true);
            setDiligenceApproverId(val);
          }}
          diligenceApproverIdError={diligenceApproverIdError}
        />
      ) : null}

      {!shouldShowSpinner && step === REVIEW_STEP ? (
        <UIKitSettingsProvider linkComponent="a" redirectAttribute="href">
          <ReviewStepContent
            members={members}
            message={message}
            investors={investors}
            documents={documents}
            diligenceEnabled={diligenceEnabled}
            numberOfCountersigners={numberOfCountersigners}
            countersignerId={countersignerId}
            countersigner2Id={countersigner2Id}
            countersigner3Id={countersigner3Id}
            diligenceApproverId={diligenceApproverId}
            diligenceClosingName={closing?.diligenceClosingName}
            diligenceClosingJurisdiction={closing?.diligenceClosingJurisdiction}
            count={count}
            plural={plural}
          />
        </UIKitSettingsProvider>
      ) : null}
    </Modal>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@passthrough/uikit';
import PencilIcon from 'mdi-material-ui/PencilOutline';

import { getInitialUseSsnValue } from 'components/lp_doc/diligence/utils';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { QuestionAnswer } from 'pages/review_v2/question_answer';
import { calculateAge, genDiligenceAnswerLabel } from 'services/utils';
import { DataValueDisplay } from 'components/data_value_display';
import { jurisdictions } from 'components/lp_doc/diligence/constants';
import { useFeatureFlags } from 'services/providers/feature_flag';
import { addressAnswerKeys } from '../constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    rowGap: theme.spacing(1),
  },
  headerContainer: {
    flex: '1',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dataContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2, 8),
  },
}));

export function ProcessedDiligenceAnswerDisplay({
  answerKey,
  showDiff,
  answerData,
  lastSubmittedAnswer,
  jurisdiction,
  isRootNode,
}) {
  let val = answerData[answerKey];
  let previous = showDiff ? lastSubmittedAnswer[answerKey] : val;
  const { SPLIT_QUESTIONNAIRE_DILIGENCE_CLOSINGS } = useFeatureFlags();

  let finalKey = answerKey;
  let priorAnswerTypeOverride = null;
  let answerType = 'AnswerType.text';

  if (addressAnswerKeys.includes(answerKey)) {
    answerType = 'AnswerType.address';
  }

  if (Array.isArray(val)) {
    val = val.join(', ');
  }
  if (Array.isArray(previous)) {
    previous = previous.join(', ');
  }

  if (answerKey === 'type' && val) {
    previous = lastSubmittedAnswer?.type || val;
    answerType = 'DiligenceAnswerType.investor_type';
  }

  if (answerKey === 'ssn') {
    answerType = 'AnswerType.ssn';

    // if node type was just set then we will not know whether the user will
    // provide an ssn so should fall back to the jurisdiction default label
    const useOtherTaxIdKey = Object.hasOwn(answerData, 'useSsn')
      ? !answerData.useSsn
      : !getInitialUseSsnValue(jurisdiction);
    if (useOtherTaxIdKey) {
      finalKey = 'taxId';
    }

    if (jurisdiction === jurisdictions.LUXEMBOURG) {
      finalKey = 'tinOrPassport';
    }

    priorAnswerTypeOverride = lastSubmittedAnswer?.useSsn
      ? 'AnswerType.ssn'
      : 'AnswerType.text';
  }

  if (answerKey === 'tin' || answerKey === 'tinEin') {
    answerType = 'DiligenceAnswerType.tin';

    if (jurisdiction === jurisdictions.LUXEMBOURG) {
      finalKey = 'tinOnly';
    }
  }

  if (answerKey === 'dob') {
    // show age in years
    if (val) {
      const dob = parse(val, 'MM/dd/yyyy', new Date());
      val = `${format(dob, 'd MMM yyyy')} (age ${calculateAge(dob)})`;
    }
    if (previous) {
      const previousDob = parse(previous, 'MM/dd/yyyy', new Date());
      previous = `${format(previousDob, 'd MMM yyyy')} (age ${calculateAge(
        previousDob,
      )})`;
    }
  }

  if (val === undefined || val === null) {
    // to ensure that an existing prior answer displays instead
    // of also showing that nothing has been provided
    if (answerKey !== 'ssn') {
      priorAnswerTypeOverride = answerType;
    }

    answerType = 'DiligenceAnswerType.not_provided';
    val = 'Not Provided';
    previous = previous || 'Not Provided';
  }

  if (
    SPLIT_QUESTIONNAIRE_DILIGENCE_CLOSINGS &&
    answerKey === 'name' &&
    isRootNode
  ) {
    answerType = 'DiligenceAnswerType.extended_legal_name';
    priorAnswerTypeOverride = 'AnswerType.text';
  }

  const question = {
    answerType,
    answer: val,
    isAsked: true,
  };

  return (
    <DataValueDisplay
      label={genDiligenceAnswerLabel(finalKey)}
      value={
        <QuestionAnswer
          question={question}
          priorAnswerTypeOverride={priorAnswerTypeOverride}
          enableAnswerFormatting
          isReviewMode
          priorAnswer={previous}
          noBold
        />
      }
    />
  );
}

export function SubmittedDataDisplay({
  jurisdiction,
  answerData,
  answerKeys,
  lastSubmittedAnswer,
  changedType,
  hideEditButtons,
  handleOpenDetailsEditModal,
  isRootNode,
}) {
  const classes = useStyles();

  if (!answerData) {
    return null;
  }
  const showDiff = !changedType && lastSubmittedAnswer;

  return (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <Typography variant="card-heading">Details</Typography>

        {hideEditButtons ? null : (
          <Button
            size="small"
            aria-label="Edit"
            variant="icon"
            onClick={handleOpenDetailsEditModal}
          >
            <PencilIcon />
          </Button>
        )}
      </div>

      <div className={classes.dataContainer}>
        {answerKeys.map((answerKey) => (
          <ProcessedDiligenceAnswerDisplay
            key={answerKey}
            answerKey={answerKey}
            showDiff={showDiff}
            answerData={answerData}
            lastSubmittedAnswer={lastSubmittedAnswer}
            jurisdiction={jurisdiction}
            isRootNode={isRootNode}
          />
        ))}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import AlertIcon from 'mdi-material-ui/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { useConfirm } from '@passthrough/uikit';
import { objectEquals } from 'services/utils';

import { Navigator } from 'components/lp_doc/navigator';
import { componentForQuestion } from 'components/lp_doc/component_for_question';
import { QuestionStepper } from 'components/lp_doc/question_stepper';
import { Question } from 'components/lp_doc/question';
import * as api from 'services/api';
import { useFeatureFlags } from 'services/providers/feature_flag';
import { useToast } from 'services/toast';
import { PdfEditor } from './pdf_editor';

import { EditSection } from './edit_section';
import { EditQuestion } from './edit_question';
import { EditSignature } from './edit_signature';
import { EditText } from './edit_text';
import {
  createDuplicatedQuestion,
  getReplacedQuestions,
  areSetsEqual,
} from './common/helpers';

import {
  frontendLabelRequired,
  choicesRequired,
  fileTypeRequired,
  SIGNATURE_BOX_TYPES,
  NAMED_SIGNATURE_BOX_TYPES,
  TAB_TYPES,
  MAX_BOX_NAME_LENGTH,
  boxTypes,
} from './constants';

const useStyles = makeStyles((theme) => ({
  questionPaper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(4),
    borderRadius: '16px',
    minHeight: '500px',
    width: '900px',
  },
  stepper: {
    width: '282px',
    backgroundColor: 'inherit',
    padding: theme.spacing(1, 2),
  },
  sectionLabel: {
    textAlign: 'left',
  },
  currentTab: {
    height: 'calc(100vh - 150px)',
    overflowY: 'scroll',
    width: '100%',
  },
  tabIcon: {
    marginRight: theme.spacing(1),
  },
  tabLabel: {
    display: 'flex',
    flexDirection: 'row',
    color: '#f57c00',
  },
  pdfEditor: {
    overflowY: 'scroll',
    height: 'calc(100vh - 150px)',
  },
  stateHeader: {
    textAlign: 'center',
    fontWeight: 600,
    color: theme.palette.primary.black,
    padding: theme.spacing(2),
  },
  leftButton: {
    float: 'left',
  },
  rightButton: {
    float: 'right',
    color: theme.palette.primary.black,
  },
}));

const MODE_NORMAL = 'MODE_NORMAL';
const MODE_RESIZE = 'MODE_RESIZE';
const MODE_MOVE = 'MODE_MOVE';

function newChildId(parentId, prefix, children) {
  const ids = children
    .map((c) => Number(c.id.split('-')[1].replace(prefix, '')))
    .filter((id) => !Number.isNaN(id));
  const maxId = Math.max(...ids) + 1;
  return `${parentId}-${prefix}${ids.length ? maxId : 1}`;
}

const getCustomCode = ({ onboarding, id, isSection, isSignature }) => {
  if (!onboarding || !id) {
    return null;
  }

  if (!onboarding.draftCustomSignatures || !onboarding.draftCustomQuestions) {
    return null;
  }

  if (isSection) {
    return null;
  }

  if (isSignature) {
    return onboarding.draftCustomSignatures[id];
  }

  return onboarding.draftCustomQuestions[id];
};

function MockNavRef() {
  const nav = new Navigator({
    lpDoc: {
      sections: [],
      docs: [],
    },
    navState: { innerStep: 0, outerStep: 0 },
    setNavState: () => {},
    setSelectedQuestionId: () => {},
  });
  return { current: nav };
}

function QuestionStepperComponent({ ...props }) {
  return (
    <QuestionStepper
      navRef={MockNavRef()}
      saving={false}
      setShowNavWarning={() => {}}
      {...props}
    />
  );
}

function clone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function cleanText(text, shouldRemoveTabs) {
  if (!text) {
    return text;
  }
  let output = text;
  // eslint-disable-next-line no-control-regex
  const regex = /[\u0000-\u0008]|[\u000B-\u000C]|[\u000E-\u001F]/gmu;
  output = output.replace(regex, '');
  if (shouldRemoveTabs) {
    output = output.replace(/\t/gm, ' ');
  }
  output = output.trim();

  return output;
}

function bundleQuestion(
  {
    id,
    frontendLabel,
    hellosignBoxes,
    question,
    text,
    links,
    deps,
    reviewed,
    choices,
    allowSelectAll,
    isRequired,
    notInteroperable,
    isAsked,
    answerType,
    hasCustomLogic,
    sectionName,
    isSection,
    isSignature,
    isText,
    signer,
    answerMeanings,
    fileType,
  },
  shouldRemoveTabs,
) {
  return {
    id,
    frontendLabel: cleanText(frontendLabel, shouldRemoveTabs),
    hellosignBoxes: clone(hellosignBoxes || []),
    question: cleanText(question, shouldRemoveTabs),
    links: clone(
      links?.map((currLink) => ({
        ...currLink,
        page: currLink.page || null,
        text: cleanText(currLink.text, shouldRemoveTabs),
      })) || [],
    ),
    deps: clone(deps || []),
    choices: clone(
      choices?.map((currChoice) => ({
        ...currChoice,
        text: cleanText(currChoice.text, shouldRemoveTabs),
      })) || [],
    ),
    allowSelectAll,
    isRequired,
    notInteroperable,
    isAsked,
    reviewed: Boolean(reviewed),
    answerType,
    hasCustomLogic,
    text: cleanText(text, shouldRemoveTabs) || '',
    sectionName: cleanText(sectionName, shouldRemoveTabs) || '',
    isSection: isSection || Boolean(sectionName),
    isSignature: isSignature || false,
    isText: isText || false,
    answerMeanings: answerMeanings || [],
    signer,
    fileType,
  };
}

function getDependencies({ questions, id }) {
  const isDependency = (dep) => {
    if (dep.questionId) {
      return dep.questionId === id;
    }
    if (dep.type === 'multi_condition') {
      if ((dep.questionIds || []).includes(id)) {
        return true;
      }
      if (dep.multiOp === 'sum_to_answer') {
        return dep.answer === id;
      }
    }
    return (dep.children || []).some(isDependency);
  };

  return questions.filter((q) => {
    let hasBoxDependencies = false;
    if (q.hellosignBoxes && q.hellosignBoxes.length) {
      const boxDependencies = q.hellosignBoxes
        .map((box) => box.deps || [])
        .flat();
      hasBoxDependencies = boxDependencies.some(isDependency);
    }
    const hasDependencies = (q.deps || []).some(isDependency);

    return hasDependencies || hasBoxDependencies;
  });
}

export function updateBoxType(box, signer) {
  const boxType = box.type;
  let [, ...outputType] = boxType.split('_');
  outputType = outputType.join('_');

  const newBoxType = `${signer}_${outputType}`;

  return { ...box, type: newBoxType };
}

export const Editor = ({
  id,
  isSaving,
  onboarding,
  questions,
  activeQuestion,
  saveQuestions,
  onSave,
  onDragEnd,
  hasUnsavedChanges,
  setHasUnsavedChanges,
  handleClickItem,
  onDraftFileUploadSuccess,
  fundId,
  questionnaireId,
  createQuestionNote,
  updateQuestionNote,
  deleteQuestionNote,
  moveQuestionNotes,
  newNotes,
  reviewedQuestionIds,
  setReviewedQuestionIds,
  selectedIds,
}) => {
  const shouldRemoveTabs = onboarding.shouldBlockTabs;
  const { MULTIPLE_DOCUMENTS } = useFeatureFlags();
  const { errorToast } = useToast();
  const confirm = useConfirm();
  const classes = useStyles();
  const [form, setForm] = useState(
    activeQuestion
      ? bundleQuestion(activeQuestion, shouldRemoveTabs)
      : bundleQuestion({}, shouldRemoveTabs),
  );
  const [suggestions, setSuggestions] = useState(null);
  const [tab, setTab] = useState(0);
  const [mode, setMode] = useState(MODE_NORMAL);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [highlightBox, setHighlightBox] = useState({
    id: null,
    timestamp: null,
  });
  const [page, setPage] = useState(1);
  const setHighlightBoxId = (boxId) =>
    setHighlightBox({ id: boxId, timestamp: Date.now() });
  const customCode = getCustomCode({
    onboarding,
    id,
    isSection: activeQuestion?.isSection,
    isSignature: activeQuestion?.isSignature,
  });
  const documents = MULTIPLE_DOCUMENTS ? onboarding.files : [];
  const [selectedDocumentName, setSelectedDocument] = React.useState(null);

  const selectDocument = (name) => {
    if (name) {
      setSelectedDocument(name);
    } else {
      setSelectedDocument(null);
    }

    if (name !== selectedDocumentName) {
      setPage(1);
    }
  };
  const selectedDocument = MULTIPLE_DOCUMENTS
    ? documents.find((doc) => doc.name === selectedDocumentName)
    : {
        id: null,
        name: null,
        fileId: null,
        fileName: null,
        order: 0,
        fileUrl: onboarding.draftFile,
      };

  const setFrontendLabel = (frontendLabel) =>
    setForm({ ...form, frontendLabel });
  const setFileType = (fileType) => setForm({ ...form, fileType });
  const setChoices = (choices) => setForm({ ...form, choices });
  const setAllowSelectAll = (allowSelectAll) =>
    setForm({ ...form, allowSelectAll });
  const setHellosignBoxes = (hellosignBoxes) =>
    setForm({ ...form, hellosignBoxes });
  const setLinks = (links) => setForm({ ...form, links });
  const setSectionName = (sectionName) => setForm({ ...form, sectionName });
  const setIsAsked = (isAsked) => setForm({ ...form, isAsked });
  const setIsRequired = (isRequired) => setForm({ ...form, isRequired });
  const setNotInteroperable = (notInteroperable) =>
    setForm({ ...form, notInteroperable });
  const setDeps = (deps) => setForm({ ...form, deps });
  const setHasCustomLogic = (hasCustomLogic) =>
    setForm({ ...form, hasCustomLogic });

  const questionReuse = activeQuestion
    ? onboarding.draftQuestionReuse[activeQuestion.id]
    : null;
  // to avoid ghost checkboxes,
  // reset choices, frontend label, and boxes state between question types
  const setAnswerType = (answerType) => {
    const setOfTypes = new Set([answerType, form.answerType]);
    const compatibleTypes = [
      new Set(['text', 'text_long']),
      new Set(['true_or_false', 'yes_or_no']),
    ];
    const choiceTypes = new Set(['multi_select', 'choice']);
    const isCompatibleTypes =
      compatibleTypes.filter((compatibleTypeSet) =>
        areSetsEqual(compatibleTypeSet, setOfTypes),
      )?.length > 0;

    const getShouldShowTypeChangeConfirmation = () => {
      // Always clear everything if there are answer meanings
      if (form.answerMeanings?.length > 0) {
        return true;
      }
      // If there are output boxes or choices, we need to check if the answer
      // types are compatible.
      // We can keep context boxes tied to question links.
      const boxesExcludingQuestionLinkContext = form.hellosignBoxes.filter(
        (b) => !(b.type === boxTypes.CONTEXT && !b.choice),
      );
      const hasBoxesOrChoices =
        boxesExcludingQuestionLinkContext.length > 0 || form.choices.length > 0;
      if (!hasBoxesOrChoices) {
        return false;
      }
      // If switching between choice and multi_select, we can keep the output
      // boxes and choices.
      if (areSetsEqual(choiceTypes, setOfTypes)) return false;
      // If the answer types are compatible, we can keep the output boxes
      // and/or choices.
      return !isCompatibleTypes;
    };

    const showTypeChangeConfirmation = getShouldShowTypeChangeConfirmation();
    if (showTypeChangeConfirmation) {
      confirm({
        description:
          "Changing the question's answer type may reset its frontend label, choices, output boxes, & answer meanings",
        destructive: true,
      })
        .then(() => {
          // We want to keep any links and context boxes that are tied to the
          // question text.
          // If we switched away from choice/multi_select, we have to remove
          // context boxes tied to the choices, and their associated links.
          const questionContextBoxes = form.hellosignBoxes.filter(
            (b) => b.type === boxTypes.CONTEXT && !b.choice,
          );
          const choiceLinkIds = form.hellosignBoxes
            .filter((b) => b.type === boxTypes.CONTEXT && b.choice)
            .map((b) => b.linkId);
          const newLinks = form.links.filter(
            (l) => !choiceLinkIds.includes(l.id),
          );
          setForm({
            ...form,
            answerType,
            frontendLabel: '',
            choices: [],
            hellosignBoxes: questionContextBoxes,
            answerMeanings: [],
            links: newLinks,
          });
        })
        .catch(() => {});
    } else {
      let newChoices = form.choices;
      if (form.answerType === 'multi_select' && answerType !== 'multi_select') {
        newChoices = form.choices.map(({ exclusive, ...attrs }) => attrs);
      }
      const getUpdatedHellosignBoxes = () => {
        if (isCompatibleTypes) {
          const fieldMapping = {
            true: 'yes',
            yes: 'true',
            false: 'no',
            no: 'false',
          };
          return form.hellosignBoxes.map((box) => {
            const newBoxField = fieldMapping[box.field];
            if (newBoxField) {
              return { ...box, field: newBoxField };
            }
            return { ...box };
          });
        }
        return form.hellosignBoxes;
      };
      const newBoxes = getUpdatedHellosignBoxes();
      setForm({
        ...form,
        answerType,
        frontendLabel: '',
        choices: newChoices,
        hellosignBoxes: newBoxes,
        answerMeanings: [],
      });
    }
  };
  const setAnswerMeanings = (answerMeanings) =>
    setForm({ ...form, answerMeanings });
  const setQuestion = (question) => setForm({ ...form, question });
  const setText = (text) => setForm({ ...form, text });

  const setSigner = (signer) =>
    setForm((prevForm) => {
      const updatedBoxes =
        prevForm.hellosignBoxes?.map((box) => updateBoxType(box, signer)) || [];

      return {
        ...prevForm,
        signer,
        hellosignBoxes: updatedBoxes,
      };
    });
  const dependencies = id ? getDependencies({ questions, id }) : [];

  function unsavedProgressAlert() {
    if (hasUnsavedChanges) {
      window.onbeforeunload = () => true;
    }

    return () => {
      window.onbeforeunload = null;
    };
  }

  useEffect(unsavedProgressAlert, [hasUnsavedChanges]);

  // TODO commenting until we can fix the performance issue
  /* eslint-disable-next-line no-unused-vars */
  const updateSuggestions = () => {
    setSuggestions(null);

    if (activeQuestion) {
      api
        .getQuestionSuggestions({
          fundId,
          questionnaireId,
          question: activeQuestion.question,
          answerType: activeQuestion.answerType,
          reuseCount: questionReuse?.reuseCount || 0,
        })
        .then((response) => {
          setSuggestions(response.data);
        });
    }
  };

  useEffect(() => {
    if (activeQuestion) {
      setForm(bundleQuestion(activeQuestion, shouldRemoveTabs));
      setTab(TAB_TYPES.edit);
      // Commenting until we can fix the performance issue.
      // updateSuggestions();
    }
  }, [activeQuestion]);

  // Clear hidden inputs
  useEffect(() => {
    if (form.answerType && !choicesRequired.includes(form.answerType)) {
      setChoices([]);
    }

    if (form.answerType && !frontendLabelRequired.includes(form.answerType)) {
      setFrontendLabel('');
    }
  }, [form.answerType]);

  useEffect(() => {
    if (activeQuestion) {
      const oldQuestion = bundleQuestion(activeQuestion, shouldRemoveTabs);
      const changed = !objectEquals(oldQuestion, form);
      setHasUnsavedChanges(changed);
    }
  }, [form]);

  useEffect(() => {
    if (activeQuestion) {
      const boxIds = activeQuestion?.hellosignBoxes?.map((b) => b.id);
      if (!boxIds?.includes(highlightBox.id)) {
        setHighlightBoxId(null);
      }
    }
  }, [activeQuestion?.id]);

  function handleSave() {
    const oldQuestions = questions;
    const newQuestion = bundleQuestion(form, shouldRemoveTabs);

    let index = oldQuestions.findIndex((q) => q.id === id);
    index = index === -1 ? oldQuestions.length : index;

    const newQuestions = [
      ...oldQuestions.slice(0, index),
      newQuestion,
      ...oldQuestions.slice(index + 1),
    ];
    onSave(newQuestions);
    setHasUnsavedChanges(false);
  }

  function handleReview(isReviewed) {
    const newQuestions = [...reviewedQuestionIds];
    if (!isReviewed) {
      newQuestions.splice(newQuestions.indexOf(id), 1);
    } else if (isReviewed && !reviewedQuestionIds.includes(id)) {
      newQuestions.push(id);
    }
    api
      .updateQuestionnaireReviewed({
        fundId,
        questionnaireId,
        reviewedQids: newQuestions,
      })
      .then(() => {
        setReviewedQuestionIds(newQuestions);
      });
  }

  function handleAddHellosignBox({
    type,
    choice = null,
    field = null,
    linkId = null,
  }) {
    const isCheckBoxType = type?.endsWith('_checkbox');

    const newBox = {
      id: newChildId(id, 'b', form.hellosignBoxes),
      page: '',
      x: '',
      y: '',
      w: '',
      h: '',
      label: id,
      text: form.text,
      type,
      choice,
      field,
      linkId,
      documentName: selectedDocumentName,
      isRequired: !isCheckBoxType,
      deps: [],
    };
    setHellosignBoxes([...form.hellosignBoxes, newBox]);
    setHighlightBoxId(newBox.id);
    setMode(MODE_RESIZE);
  }

  const [loadingReplace, setLoadingReplace] = useState(false);
  async function handleReplace() {
    setLoadingReplace(true);
    // Point all questions that depend on oldQ to the newQ
    const oldQ = questions.find((q) => q.id === id);
    const newQuestion = createDuplicatedQuestion(oldQ, questions);
    const updatedQuestions = getReplacedQuestions(oldQ, newQuestion, questions);

    handleClickItem(newQuestion);
    try {
      await Promise.allSettled([
        saveQuestions(updatedQuestions),
        moveQuestionNotes(oldQ.id, newQuestion.id),
      ]);
    } catch (e) {
      errorToast('Failed to replace question');
    } finally {
      setLoadingReplace(false);
    }
  }

  function replaceHellosignBox(updatedBoxData) {
    const newBoxes = [...form.hellosignBoxes];
    const targetBoxIndex = newBoxes.findIndex(
      (b) => b.id === updatedBoxData.id,
    );

    if (targetBoxIndex === -1) {
      return;
    }

    let newBox = {
      ...form.hellosignBoxes[targetBoxIndex],
      ...updatedBoxData,
    };
    newBox = {
      ...newBox,
      // If is a signature enforce width to be 210 and height to be 15
      ...(SIGNATURE_BOX_TYPES.includes(newBox.type) ? { w: 210, h: 15 } : {}),
      // ensure all checkboxes are 15x15 squares
      ...(newBox.type.endsWith('_checkbox') ? { w: 15, h: 15 } : {}),
    };
    if (newBox.type.endsWith('_checkbox')) {
      newBox.isRequired = false;
    }

    newBoxes.splice(targetBoxIndex, 1, newBox);
    setHellosignBoxes(newBoxes);
  }

  function deleteHellosignBox(newBoxId) {
    const newBoxes = [...form.hellosignBoxes];
    const index = newBoxes.findIndex((b) => b.id === newBoxId);
    newBoxes.splice(index, 1);

    setHellosignBoxes(newBoxes);
  }

  function handleAddLink() {
    setLinks([
      ...form.links,
      { id: uuidv4(), text: '', page: null, url: null },
    ]);
  }

  function replaceLink(newLink) {
    const newLinks = [...form.links];
    const index = newLinks.findIndex((link) => link.id === newLink.id);
    newLinks.splice(index, 1, newLink);
    setLinks(newLinks);
  }

  function deleteLink(newLinkId) {
    const newLinks = [...form.links];
    const index = newLinks.findIndex((link) => link.id === newLinkId);
    newLinks.splice(index, 1);
    setForm({
      ...form,
      links: newLinks,
      hellosignBoxes: [
        ...form.hellosignBoxes.filter((b) => b.linkId !== newLinkId),
      ],
    });
  }

  function handleAddChoiceLink(choice) {
    const linkId = uuidv4();
    const link = { id: linkId, text: '', page: null, url: null };
    const links = [...form.links, link];
    const newBox = {
      id: newChildId(id, 'b', form.hellosignBoxes),
      page: '',
      x: '',
      y: '',
      w: '',
      h: '',
      label: id,
      text: form.text,
      type: boxTypes.CONTEXT,
      choice,
      field: null,
      linkId,
      isRequired: true,
      deps: [],
    };
    const boxes = [...form.hellosignBoxes, newBox];
    setForm({
      ...form,
      hellosignBoxes: boxes,
      links,
    });
    setHighlightBoxId(newBox.id);
    setMode(MODE_RESIZE);
  }

  function handleAddChoice() {
    setChoices([
      ...form.choices,
      { id: newChildId(id, 'c', form.choices), text: '' },
    ]);
  }

  function replaceChoice(newChoice) {
    const newChoices = [...form.choices];
    const index = newChoices.findIndex((c) => c.id === newChoice.id);
    newChoices.splice(index, 1, newChoice);
    setChoices(newChoices);
  }

  function deleteChoice(newChoiceId) {
    const newChoices = [...form.choices];
    const index = newChoices.findIndex((c) => c.id === newChoiceId);
    newChoices.splice(index, 1);
    const deletedLinkIds = form.hellosignBoxes
      .filter((b) => b.choice === newChoiceId)
      .map((b) => b.linkId)
      .filter(Boolean);
    const links = form.links.filter((l) => !deletedLinkIds.includes(l.id));
    setForm({
      ...form,
      choices: newChoices,
      hellosignBoxes: [
        ...form.hellosignBoxes.filter((b) => b.choice !== newChoiceId),
      ],
      links,
    });
  }

  function copyChoice(choiceIdToCopy) {
    const newChoices = [...form.choices];
    const newLinks = [...form.links];
    const oldChoiceIndex = newChoices.findIndex((c) => c.id === choiceIdToCopy);
    const newChoice = {
      ...newChoices[oldChoiceIndex],
      id: newChildId(id, 'c', newChoices),
    };
    const hellosignBoxesToCopy = form.hellosignBoxes.filter(
      (b) => b.choice === choiceIdToCopy,
    );
    const newHellosignBoxes = [...form.hellosignBoxes];

    newChoices.splice(oldChoiceIndex + 1, 0, newChoice);
    hellosignBoxesToCopy.forEach((boxToCopy) => {
      if (boxToCopy.type === boxTypes.CONTEXT) {
        const newLinkId = uuidv4();
        const linkToCopy = form.links.find((l) => l.id === boxToCopy.linkId);

        newHellosignBoxes.push({
          ...boxToCopy,
          id: newChildId(id, 'b', newHellosignBoxes),
          choice: newChoice.id,
          linkId: newLinkId,
        });
        newLinks.push({
          ...linkToCopy,
          id: newLinkId,
        });
      } else {
        newHellosignBoxes.push({
          ...boxToCopy,
          id: newChildId(id, 'b', newHellosignBoxes),
          choice: newChoice.id,
        });
      }
    });

    setForm({
      ...form,
      choices: newChoices,
      hellosignBoxes: newHellosignBoxes,
      links: newLinks,
    });
  }

  function isValidChoice(currChoice) {
    // choices do not necessarily need a checkbox assigned to them to be valid,
    // just need non-whitespace text associated with them at bare minimum
    return currChoice.text.trim() !== '';
  }

  function minimalChoiceRequirementsMet() {
    if (choicesRequired.includes(form.answerType)) {
      return form.choices.some(isValidChoice);
    }

    // trivially true for answerTypes not in choicesRequired
    return true;
  }

  function isDepsValid(dep) {
    if (!dep.children) {
      return dep.answer !== undefined || dep.choice !== undefined;
    }
    return dep.children.every(isDepsValid);
  }

  function isBoxPlacementValid(box) {
    return (
      Boolean(box.page) &&
      box.page > 0 &&
      Boolean(box.x) &&
      box.x > 0 &&
      Boolean(box.y) &&
      box.y > 0 &&
      Boolean(box.w) &&
      box.w > 0 &&
      Boolean(box.h) &&
      box.h > 0
    );
  }

  function isNameValid(boxName) {
    return boxName.length < MAX_BOX_NAME_LENGTH;
  }

  function doNamedSignatureBoxesHaveValidNames(boxes) {
    return boxes.every((box) => {
      if (NAMED_SIGNATURE_BOX_TYPES.includes(box.type)) {
        return Boolean(box.name) && isNameValid(box.name);
      }
      return true;
    });
  }

  function boxesAreValid(boxes) {
    return boxes.every((box) => {
      const validBoxDeps = box.deps ? box.deps.every(isDepsValid) : true;
      return isBoxPlacementValid(box) && validBoxDeps;
    });
  }

  function isLinkValid(link) {
    const linkBoxes = form.hellosignBoxes.filter(
      (box) => box.linkId === link.id,
    );
    const hasValidBoxes =
      linkBoxes.length > 0 &&
      linkBoxes.every((box) => isBoxPlacementValid(box));

    const hasValidPages =
      link.page !== '' && link.page !== null && link.page > 0;

    const hasValidUrls = link.url !== '' && link.url !== null;

    return link.text !== '' && (hasValidPages || hasValidBoxes || hasValidUrls);
  }

  function isValid() {
    if (form.isSection) return Boolean(form.sectionName);
    if (form.isSignature) {
      return (
        form.hellosignBoxes.length > 0 &&
        id &&
        form.deps.every(isDepsValid) &&
        boxesAreValid(form.hellosignBoxes) &&
        doNamedSignatureBoxesHaveValidNames(form.hellosignBoxes)
      );
    }
    if (form.isText) {
      return id && form.text !== '';
    }

    return (
      id &&
      form.answerType &&
      minimalChoiceRequirementsMet() &&
      (form.frontendLabel ||
        !frontendLabelRequired.includes(form.answerType)) &&
      (form.fileType || !fileTypeRequired.includes(form.answerType)) &&
      form.isRequired !== '' &&
      form.isAsked !== '' &&
      form.question !== '' &&
      form.deps.every(isDepsValid) &&
      form.links.every(isLinkValid) &&
      boxesAreValid(form.hellosignBoxes)
    );
  }

  const selectedBox = {
    ...highlightBox,
    ...form.hellosignBoxes.find((b) => b.id === highlightBox.id),
  };

  function renderEditTab() {
    if (!id) return 'Select something to edit or add a question.';
    if (form.isSection) {
      return (
        <EditSection
          sectionId={id}
          sectionName={form.sectionName}
          setSectionName={setSectionName}
          handleSave={handleSave}
          hasUnsavedChanges={hasUnsavedChanges}
          isValid={isValid}
          isSaving={isSaving}
        />
      );
    }
    if (form.isSignature) {
      return (
        <EditSignature
          id={id}
          isAsked={form.isAsked}
          setIsAsked={setIsAsked}
          hellosignBoxes={form.hellosignBoxes}
          replaceHellosignBox={replaceHellosignBox}
          deleteHellosignBox={deleteHellosignBox}
          setDragSelected={(boxId) => {
            setHighlightBoxId(boxId);
            setMode(MODE_RESIZE);
          }}
          setPage={setPage}
          handleAddHellosignBox={handleAddHellosignBox}
          deps={form.deps}
          setDeps={setDeps}
          questions={questions}
          hasCustomLogic={form.hasCustomLogic}
          setHasCustomLogic={setHasCustomLogic}
          onDragEnd={onDragEnd}
          handleSave={handleSave}
          hasUnsavedChanges={hasUnsavedChanges}
          isValid={isValid}
          isSaving={isSaving}
          highlightBox={highlightBox}
          signer={form.signer}
          setSigner={setSigner}
          setHighlightBoxId={setHighlightBoxId}
          handleClickItem={handleClickItem}
          createQuestionNote={createQuestionNote}
          updateQuestionNote={updateQuestionNote}
          deleteQuestionNote={deleteQuestionNote}
          newNotes={newNotes}
          onboardingLogs={onboarding.onboardingLogs}
          handleReview={handleReview}
          currentState={onboarding.state}
          reviewedQuestionIds={reviewedQuestionIds}
          setReviewedQuestionIds={setReviewedQuestionIds}
          selectedIds={selectedIds}
          selectDocument={selectDocument}
        />
      );
    }
    if (form.isText) {
      return (
        <EditText
          id={id}
          text={form.text}
          setText={setText}
          isAsked={form.isAsked}
          setIsAsked={setIsAsked}
          hellosignBoxes={form.hellosignBoxes}
          replaceHellosignBox={replaceHellosignBox}
          handleAddHellosignBox={handleAddHellosignBox}
          deleteHellosignBox={deleteHellosignBox}
          setDragSelected={(boxId) => {
            setHighlightBoxId(boxId);
            setMode(MODE_RESIZE);
          }}
          onDragEnd={onDragEnd}
          setPage={setPage}
          deps={form.deps}
          setDeps={setDeps}
          questions={questions}
          handleSave={handleSave}
          hasUnsavedChanges={hasUnsavedChanges}
          isValid={isValid}
          isSaving={isSaving}
          highlightBox={highlightBox}
          setHighlightBoxId={setHighlightBoxId}
          handleClickItem={handleClickItem}
          createQuestionNote={createQuestionNote}
          updateQuestionNote={updateQuestionNote}
          deleteQuestionNote={deleteQuestionNote}
          newNotes={newNotes}
          onboardingLogs={onboarding.onboardingLogs}
          handleReview={handleReview}
          currentState={onboarding.state}
          reviewedQuestionIds={reviewedQuestionIds}
          setReviewedQuestionIds={setReviewedQuestionIds}
          selectedIds={selectedIds}
          selectDocument={selectDocument}
        />
      );
    }

    return (
      <EditQuestion
        id={id}
        answerType={form.answerType}
        setAnswerType={setAnswerType}
        answerMeanings={form.answerMeanings}
        setAnswerMeanings={setAnswerMeanings}
        question={form.question}
        setQuestion={setQuestion}
        links={form.links}
        replaceLink={replaceLink}
        deleteLink={deleteLink}
        handleAddLink={handleAddLink}
        isAsked={form.isAsked}
        setIsAsked={setIsAsked}
        isRequired={form.isRequired}
        setIsRequired={setIsRequired}
        notInteroperable={form.notInteroperable}
        setNotInteroperable={setNotInteroperable}
        frontendLabel={form.frontendLabel}
        setFrontendLabel={setFrontendLabel}
        fileType={form.fileType}
        setFileType={setFileType}
        hellosignBoxes={form.hellosignBoxes}
        replaceHellosignBox={replaceHellosignBox}
        deleteHellosignBox={deleteHellosignBox}
        setDragSelected={(boxId) => {
          setHighlightBoxId(boxId);
          setMode(MODE_RESIZE);
        }}
        setPage={setPage}
        handleAddHellosignBox={handleAddHellosignBox}
        choices={form.choices}
        setChoices={setChoices}
        replaceChoice={replaceChoice}
        handleAddChoice={handleAddChoice}
        deleteChoice={deleteChoice}
        copyChoice={copyChoice}
        allowSelectAll={form.allowSelectAll}
        setAllowSelectAll={setAllowSelectAll}
        deps={form.deps}
        setDeps={setDeps}
        questions={questions}
        hasCustomLogic={form.hasCustomLogic}
        setHasCustomLogic={setHasCustomLogic}
        onDragEnd={onDragEnd}
        handleSave={handleSave}
        hasUnsavedChanges={hasUnsavedChanges}
        isValid={isValid}
        isSaving={isSaving}
        highlightBox={highlightBox}
        setHighlightBoxId={setHighlightBoxId}
        dependencies={dependencies}
        handleClickItem={handleClickItem}
        handleReplace={handleReplace}
        loadingReplace={loadingReplace}
        suggestions={suggestions}
        questionReuse={questionReuse}
        createQuestionNote={createQuestionNote}
        updateQuestionNote={updateQuestionNote}
        deleteQuestionNote={deleteQuestionNote}
        newNotes={newNotes}
        onboardingLogs={onboarding.onboardingLogs}
        handleReview={handleReview}
        currentState={onboarding.state}
        reviewedQuestionIds={reviewedQuestionIds}
        setReviewedQuestionIds={setReviewedQuestionIds}
        selectedIds={selectedIds}
        handleAddChoiceLink={handleAddChoiceLink}
        selectDocument={selectDocument}
      />
    );
  }

  function renderPreviewCodeTab() {
    return (
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <Paper className={classes.questionPaper} variant="outlined">
            <pre>{customCode}</pre>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  function renderPreviewTab() {
    if (form.isSection) {
      const steps = questions.filter((q) => q.sectionName);
      const activeStep = steps.findIndex((q) => q.id === id);
      return (
        <Stepper
          nonLinear
          orientation="vertical"
          className={classes.stepper}
          activeStep={activeStep}
        >
          {steps.map((step) => (
            <Step key={step.id}>
              <StepButton
                className={classes.sectionLabel}
                completed={false}
                onClick={() => {}}
              >
                {step.id === id ? form.sectionName : step.sectionName}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      );
    }

    if (!form.answerType) {
      return "Can't preview";
    }

    const Component = componentForQuestion({
      answerType: `AnswerType.${form.answerType}`,
    });

    const questionPrefixText = !form.isRequired ? '(Optional) ' : null;

    // TODO: This preview is different from what the LP sees in the
    // actual questionnaire. Ideally this would be refactored to be more
    // consistent with the real workflow instead of needing to hack the logic
    // to make look the same here.
    const pdfBoxes = form.hellosignBoxes.map((box) => {
      const { choice: choiceId, ...rest } = box;
      return { choiceId, ...rest };
    });
    const QuestionPromptComponent = ({
      short,
      addPrefixText = true,
      children,
    }) => (
      <Question
        links={form.links || []}
        prefixText={addPrefixText ? questionPrefixText : null}
        short={short}
        question={form.question}
        pdfBoxes={pdfBoxes}
      >
        {children}
      </Question>
    );

    return (
      <Grid container justify="center" alignItems="center">
        <Grid item>
          <Paper className={classes.questionPaper} variant="outlined">
            <Component
              choices={form.choices.map((c) => ({
                id: c.id,
                text: c.text,
                exclusive: c.exclusive,
              }))}
              allowSelectAll={form.allowSelectAll}
              question={form.question}
              links={form.links}
              frontendLabel={form.frontendLabel}
              label={id}
              pdfBoxes={pdfBoxes}
              QuestionStepper={QuestionStepperComponent}
              setSaving={() => {}}
              updateLpDoc={() => new Promise((resolve) => resolve())}
              onAnswer={() => {}}
              lpDoc={{}}
              QuestionPromptComponent={QuestionPromptComponent}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }

  function handleClickBox(box, offset) {
    if (box.id === selectedBox.id) {
      setMode(MODE_MOVE);
      setDragOffset(offset);
    } else {
      setHighlightBoxId(box.id);
    }

    const question = questions.find((q) =>
      (q.hellosignBoxes || []).map((h) => h.id).includes(box.id),
    );

    if (question) {
      if (!activeQuestion || question.id !== activeQuestion.id) {
        // If we're not changing questions, no need to call this
        handleClickItem(question);
      }
    }
  }

  const showPreview =
    activeQuestion && !activeQuestion.isText && !activeQuestion.isSignature;

  return (
    <Grid container>
      <Grid item lg={7} md={12} className={classes.currentTab}>
        {activeQuestion ? (
          <>
            <div style={{ textAlign: 'left' }}>
              <Tabs
                value={tab}
                onChange={(e, v) => setTab(v)}
                aria-label="tabs"
                centered
              >
                <Tab
                  id="tab-0"
                  aria-controls="tabs-0"
                  label="Edit"
                  value={TAB_TYPES.edit}
                />
                {showPreview ? (
                  <Tab
                    id="tab-1"
                    aria-controls="tabs-1"
                    label="Preview"
                    value={TAB_TYPES.preview}
                  />
                ) : null}
                {customCode ? (
                  <Tab
                    id="tab-2"
                    aria-controls="tabs-2"
                    value={TAB_TYPES.custom_code}
                    label={
                      <div className={classes.tabLabel}>
                        <AlertIcon
                          className={classes.tabIcon}
                          colorAction={tab === TAB_TYPES.custom_code}
                        />
                        Custom code
                      </div>
                    }
                  />
                ) : null}
              </Tabs>
            </div>
            {id ? (
              <>
                {tab === 0 ? renderEditTab() : null}
                {tab === 1 ? renderPreviewTab() : null}
                {tab === 2 ? renderPreviewCodeTab() : null}
              </>
            ) : null}
          </>
        ) : null}
      </Grid>
      <Grid item lg={5} md={12} className={classes.pdfEditor}>
        <PdfEditor
          mode={mode}
          selectedBox={selectedBox}
          dragOffset={dragOffset}
          onTriggerResize={() => {
            setMode(MODE_RESIZE);
          }}
          onTranslate={(offsets) => {
            if (mode === MODE_NORMAL) {
              replaceHellosignBox({
                id: selectedBox.id,
                x: selectedBox.x + offsets.x,
                y: selectedBox.y + offsets.y,
              });
              setMode(MODE_NORMAL);
            }
          }}
          onDragEnd={(rect) => {
            if (mode !== MODE_NORMAL) {
              replaceHellosignBox({
                id: selectedBox.id,
                x: rect.startX,
                y: rect.startY,
                w: rect.w,
                h: rect.h,
                page: rect.page,
                documentName: MULTIPLE_DOCUMENTS ? selectedDocumentName : null,
              });
            }
            setMode(MODE_NORMAL);
          }}
          boxes={
            MULTIPLE_DOCUMENTS
              ? form.hellosignBoxes.filter(
                  (box) => (box.documentName || null) === selectedDocumentName,
                )
              : form.hellosignBoxes
          }
          fileUrl={onboarding.draftFile}
          questions={questions}
          activeQuestion={activeQuestion}
          onClickBox={handleClickBox}
          page={page}
          setPage={setPage}
          onDraftFileUploadSuccess={onDraftFileUploadSuccess}
          selectDocument={selectDocument}
          selectedDocument={selectedDocument}
          documents={documents}
        />
      </Grid>
    </Grid>
  );
};

import { Tooltip } from '@material-ui/core';
import { Link, Typography } from '@passthrough/uikit';
import * as React from 'react';
import { useFund } from 'services/providers/fund';
import * as urls from 'services/urls';

const ConditionalLink = ({ children, id }) => {
  const [fund] = useFund();
  return id ? (
    <Link href={urls.closingUrl({ fundId: fund.id, closingId: id })}>
      {children}
    </Link>
  ) : (
    <span>{children}</span>
  );
};

export const SubdocNames = ({ subdocs }) => {
  const joinedSubdocNames = subdocs.reduce((acc, { name, id }, i) => {
    const nameElement =
      name.length > 20 ? (
        <Tooltip
          title={
            <Typography variant="label" color="inherit">
              {name}
            </Typography>
          }
        >
          <ConditionalLink id={id}>{`${name.slice(0, 20)}...`}</ConditionalLink>
        </Tooltip>
      ) : (
        <ConditionalLink id={id}>{name}</ConditionalLink>
      );
    if (i === 0) {
      return [nameElement];
    }
    return [...acc, ', ', nameElement];
  }, []);
  return joinedSubdocNames;
};
